

import {
    ref,
    defineComponent,
    onMounted,
    onActivated,
    createVNode,
    reactive,
} from "vue";

import { _Client } from "@/api-client";
import LogDetail from '@/components/LogDetail/index.vue'
import {
    GetAllRegionRep, PagedResultOfExaminerInfoDto,
    SearchMonitorRecordsByExaminerRequest,
    PagedResultOfLifeSkillMonitorRecordsDto,
    PagedResultOfSpeakingMonitorRecordsDto,
    PagedResultOfExaminerMonitorRecordsLogDto,
    GetMonitorRecordLogByExaminerIdRequest,
    SearchExaminerRequest
} from "@/api-client/client";

import { LogDetailModel, LogModel } from '@/api-client/modal';
import { List, Modal, message } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

import Pagination from '@/components/Pagination/index.vue';

const monitorType = ref<string>('speaking');

//// MonitorRecords  主列表（考官列表）
const columns = [
    {
        title: 'Region',
        dataIndex: 'regionIdName',
    },
    {
        title: 'Examiner No.',
        dataIndex: 'examinerNo'
    },
    {
        title: 'Examiner Name',
        dataIndex: 'examinerName'
    },{
        title: 'Spk Profile Status',
        dataIndex: 'speakingProfileStatus'
    },
    {
        title: 'Action',
        dataIndex: 'action',
        fixed: 'right',
        width: 200
    }
]

//// MonitorRecords  主列表（考官列表）
const speakingMonitorRecordsColumns = [
    {
        title: 'Monitor Date',
        dataIndex: 'monitorDateStr',
    },
    {
        title: 'Monitor Stage',
        dataIndex: 'monitorStage'
    },
    {
        title: 'IDP',
        dataIndex: 'idpStr'
    },
    {
        title: 'Monitor Type',
        dataIndex: 'monitorType'
    },
    {
        title: 'Final Result',
        dataIndex: 'finalResult'
    },
    {
        title: 'Proceduress result',
        dataIndex: 'proceduresResult'
    }
    ,
    {
        title: 'Rating result',
        dataIndex: 'ratingResult'
    },
    {
        title: 'Follow up completed',
        dataIndex: 'followupCompletedStr'
    },
    {
        title: 'Follow up Date',
        dataIndex: 'followUpDateStr'
    },
    {
        title: 'Follow up Trainer',
        dataIndex: 'followUpTrainer'
    },
    {
        title: 'Follow up IDP',
        dataIndex: 'followUpIDPStr'
    }
]

const lifeSkillMonitorRecordsColumns = [
    {
        title: 'Monitor Date',
        dataIndex: 'monitorDateStr',
    },
    {
        title: 'Level',
        dataIndex: 'level'
    },
    {
        title: 'Monitor Stage',
        dataIndex: 'monitorStage'
    },
    {
        title: 'IDP',
        dataIndex: 'idpStr'
    },
    {
        title: 'Monitor Type',
        dataIndex: 'monitorType'
    },
    {
        title: 'Result',
        dataIndex: 'result'
    },
    {
        title: 'NonStandardProcedurePoints',
        dataIndex: 'nonStandardProcedurePoints'
    }
    ,
    {
        title: 'Follow up completed',
        dataIndex: 'followupCompletedStr'
    },
    {
        title: 'Follow up Date',
        dataIndex: 'followUpDateStr'
    },
    {
        title: 'Follow up Trainer',
        dataIndex: 'followUpTrainer'
    },
    {
        title: 'Follow up IDP',
        dataIndex: 'followUpIDPStr'
    }
]

export default defineComponent({
    components: {

        Pagination,
        LogDetail
    },
    setup() {
        const tableLoading = ref<boolean>(false);
        const monitorRecordsTableLoading = ref<boolean>(false);
        const tableSource = ref(new PagedResultOfExaminerInfoDto());
        const speakingMonitorTableSource = ref(new PagedResultOfSpeakingMonitorRecordsDto());
        const lifeSkillMonitorTableSource = ref(new PagedResultOfLifeSkillMonitorRecordsDto());
        const titleMonitorRecords = ref<String>("");
        const showMonitorRecordsModal = ref<boolean>(false);
        const searchParames = ref<SearchExaminerRequest>(new SearchExaminerRequest({ pageIndex: 1, pageSize: 10 }));
        const searchMonitorRecordsParames = ref<SearchMonitorRecordsByExaminerRequest>(new SearchMonitorRecordsByExaminerRequest({ examinerId: "", pageIndex: 1, pageSize: 10 }));
        const searchSpeakingRecordsParames = ref<SearchMonitorRecordsByExaminerRequest>(new SearchMonitorRecordsByExaminerRequest({ examinerId: "", pageIndex: 1, pageSize: 10 }));
        const searchLifeSkillRecordsParames = ref<SearchMonitorRecordsByExaminerRequest>(new SearchMonitorRecordsByExaminerRequest({ examinerId: "", pageIndex: 1, pageSize: 10 }));
        const logDetailRef = ref(null)
        const region = reactive<{
            regionList: GetAllRegionRep[],
        }>({
            regionList: new Array<GetAllRegionRep>(),

        });

        const currentComponent = ref<string>();

        const SwitchTag = (name: string, index: number) => {
            currentComponent.value = name
            var elements = document.getElementsByClassName("tab-bar");

            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove("isChecked");
            }
            elements[index].classList.add("isChecked")

        }
        const logState = reactive<{
            logSearchParames: GetMonitorRecordLogByExaminerIdRequest,
            logDetail: LogDetailModel,
            showLogDialog: Function,
            getLogList: Function
        }>({
            logSearchParames: new GetMonitorRecordLogByExaminerIdRequest(<GetMonitorRecordLogByExaminerIdRequest>{ pageIndex: 1 }),
            logDetail: new LogDetailModel(),

            showLogDialog: async (examinerId: string) => {
                logState.logSearchParames.pageIndex = 1;
                logState.logSearchParames.examinerId = examinerId
                await logState.getLogList()
            },

            getLogList: async () => {
                try {
                    let logDetail = JSON.parse(JSON.stringify(await _Client.monitorRecordsClient.searchMonitorRecordLogByExaminerId(logState.logSearchParames)))
                    if (!logDetail) {
                        return;
                    }
                    logDetail.list = logDetail.list?.map((logItem: { createdOn: Date; operation: string; createdBy: string; }) => {
                        return new LogModel({
                            createdTime: logItem.createdOn,
                            operation: logItem.operation,
                            userName: logItem.createdBy
                        })
                    })
                    logState.logDetail = new LogDetailModel(logDetail)
                }
                finally {
                    (logDetailRef.value as any).showLogModal = true
                }
            }
        })


        async function getRegionList() {
            region.regionList = await _Client.regionClient.getAll(true)
        }


        const getList = (() => {
            tableLoading.value = true;
            _Client.monitorRecordsClient.search(searchParames.value!).then(rep => {
                tableSource.value = rep;
            }).finally(() => tableLoading.value = false)
        })

        const resetSearchParams = () => {
            searchParames.value = new SearchExaminerRequest(<SearchExaminerRequest>{ pageIndex: 1 })
            getList();
        }

        const getmonitorRecords = (() => {
            monitorRecordsTableLoading.value = true;
            getSpeakingMonitorRecords()
            getLiftSkillMonitorRecords()
            monitorRecordsTableLoading.value = false
        })
        const getSpeakingMonitorRecords = (() => {
            _Client.monitorRecordsClient.searchSpeakingMonitorRecordByExaminerId(searchSpeakingRecordsParames.value!).then(rep => {
                speakingMonitorTableSource.value = rep;
            }).finally()
        })
        const getLiftSkillMonitorRecords = (() => {
            _Client.monitorRecordsClient.searchLifeSkillMonitorRecordByExaminerId(searchLifeSkillRecordsParames.value!).then(rep => {
              lifeSkillMonitorTableSource.value = rep;
            }).finally(() => monitorRecordsTableLoading.value = false)

        })

        const showmonitorRecords = ((examinerNo: string, examinerId: string, examinerName: string) => {
            searchSpeakingRecordsParames.value.pageIndex = 1;
            searchLifeSkillRecordsParames.value.pageIndex = 1;
            showMonitorRecordsModal.value = true;
            titleMonitorRecords.value = "View Detail： " + examinerName + " " + examinerNo;
            searchSpeakingRecordsParames.value.examinerId = examinerId;
            searchLifeSkillRecordsParames.value.examinerId = examinerId;
            getmonitorRecords();
        })

        const closemonitorRecords = (() => {
            showMonitorRecordsModal.value = false;
            titleMonitorRecords.value = "";
            searchSpeakingRecordsParames.value.examinerId = "";
            searchLifeSkillRecordsParames.value.examinerId = "";
        })

        const search = (() => {
            searchParames.value.pageIndex = 1;
            getList();
        })

        onMounted(() => {
            getRegionList();
            getList();
        });

        onActivated(() => {

        });


        return {
            tableLoading,
            monitorRecordsTableLoading,
            showMonitorRecordsModal,
            region,
            columns,
            speakingMonitorRecordsColumns,
            tableSource,
            speakingMonitorTableSource,
            lifeSkillMonitorTableSource,
            searchParames,
            searchMonitorRecordsParames,
            titleMonitorRecords,
            close,
            getList,
            resetSearchParams,
            getmonitorRecords,
            showmonitorRecords,
            closemonitorRecords,
            SwitchTag,
            monitorType,
            lifeSkillMonitorRecordsColumns,
            logState,
            logDetailRef,
            search,
            searchSpeakingRecordsParames,
            searchLifeSkillRecordsParames,
            getSpeakingMonitorRecords,
            getLiftSkillMonitorRecords
        };
    }
});

